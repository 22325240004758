import React from "react";
import { Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import {
  BannerAdmin,
  ChangePasswordAdmin,
  FaqAdmin,
  Home,
  LoginAdmin,
  ProductsAdmin,
  SponsorAdmin,
  WhyAdmin,
} from "./pages";
import { LoggedInPrivateRoutes, PrivateRoutes } from "./utils";
import { AdminLayout, FullPageLayout } from "./layouts";
import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./App.css";

function App() {
  return (
    <>
      <Routes>
        <Route element={<FullPageLayout />}>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
        </Route>
        <Route element={<LoggedInPrivateRoutes />}>
          <Route path="admin" element={<LoginAdmin />} />
        </Route>
        <Route element={<PrivateRoutes />}>
          <Route path="admin/*" element={<AdminLayout />}>
            <Route path="banner" element={<BannerAdmin />} />
            <Route path="products" element={<ProductsAdmin />} />
            <Route path="why" element={<WhyAdmin />} />
            <Route path="faq" element={<FaqAdmin />} />
            <Route path="sponsor" element={<SponsorAdmin />} />
            <Route path="change-password" element={<ChangePasswordAdmin />} />
          </Route>
        </Route>
      </Routes>
      <ToastContainer />
    </>
  );
}

export default App;
