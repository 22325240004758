import React from "react";
import {
  faHouse,
  faBoxOpen,
  faStar,
  faBellConcierge,
  faAddressCard,
  faBars,
  faPassport,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import {
  useProSidebar,
  Sidebar,
  Menu,
  MenuItem,
  MenuItemStyles,
  CSSObject,
} from "react-pro-sidebar";
import { LOGO, LOGO_SMALL } from "../../assets/image";
import { Outlet, Link, useNavigate } from "react-router-dom";
import styles from "./AdminLayout.module.css";

const AdminLayout: React.FC = () => {
  const { toggleSidebar, collapseSidebar, broken, collapsed } = useProSidebar();
  const navigate = useNavigate();
  const menuRootStyle: CSSObject = {
    color: "white",
    hover: {
      backgroundColor: "#222222",
      color: "#44596e",
    },
    marginTop: 30,
  };

  const menuItemStyles: MenuItemStyles = {
    root: {
      fontSize: "13px",
      fontWeight: 400,
    },
    SubMenuExpandIcon: {
      color: "#b6b7b9",
    },
    subMenuContent: ({ level }) => ({
      backgroundColor: "#222222",
    }),
    button: {
      "&:hover": {
        backgroundColor: "#19b6ff",
        color: "#FFFFFF",
      },
    },
    label: ({ open }) => ({
      fontWeight: open ? 600 : undefined,
      color: '#000000',
    }),
    icon: {
      color: "black",
    }
  };

  const renderSidebar = () => {
    return (
      <Sidebar breakPoint="lg" backgroundColor="#f0f5ff">
        <div className="d-flex flex-column h-100">
          <div
            className={`w-100 d-flex justify-content-center align-items-center pt-4 pb-2`}
          >
            <Image src={collapsed ? LOGO_SMALL : LOGO }  width={80} />
          </div>
          <Menu rootStyles={menuRootStyle} menuItemStyles={menuItemStyles}>
            <MenuItem
              component={<Link className={styles.link} to={"/admin/banner"} />}
              icon={<FontAwesomeIcon icon={faHouse} />}
            >
              Banner
            </MenuItem>
            <MenuItem
              component={
                <Link className={styles.link} to={"/admin/products"} />
              }
              icon={<FontAwesomeIcon icon={faBoxOpen} />}
            >
              Product
            </MenuItem>
            <MenuItem
              component={
                <Link className={styles.link} to={"/admin/why"} />
              }
              icon={<FontAwesomeIcon icon={faStar} />}
            >
              Why
            </MenuItem>
            <MenuItem
              component={
                <Link className={styles.link} to={"/admin/faq"} />
              }
              icon={<FontAwesomeIcon icon={faBellConcierge} />}
            >
              FAQ
            </MenuItem>
            <MenuItem
              component={<Link className={styles.link} to={"/admin/sponsor"} />}
              icon={<FontAwesomeIcon icon={faAddressCard} />}
            >
              Sponsor
            </MenuItem>
            <MenuItem
              component={<Link className={styles.link} to={"/admin/change-password"} />}
              icon={<FontAwesomeIcon icon={faPassport} />}
            >
              Change Password
            </MenuItem>
          </Menu>
        </div>
      </Sidebar>
    );
  };

  return (
    <div className="d-flex vh-100 w-100">
      {renderSidebar()}
      <Container fluid>
        <Row
          className={
            "pt-4 pb-3 align-items-center justify-content-between border-bottom p-0"
          }
        >
          <Col xs="2">
            <FontAwesomeIcon
              icon={faBars}
              className="font-24"
              role={"button"}
              onClick={() => {
                broken ? toggleSidebar() : collapseSidebar();
              }}
            />
          </Col>
          <Col xs="1">
            <Button
              onClick={() => {
                localStorage.removeItem("isLoggedIn");
                localStorage.removeItem("token");
                localStorage.removeItem("user");
                navigate("/");
              }}
              variant="muted"
            >
              Logout
            </Button>
          </Col>
        </Row>
        <Row className={`${styles.content_container} overflow-scroll`}>
          <Outlet />
        </Row>
      </Container>
    </div>
  );
};

export { AdminLayout };
