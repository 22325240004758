import { toast } from "react-toastify";
import { sendEmailApi, updateByElementIdApi, uploadFileApi } from "../apis";
import S3FileUpload from "react-s3";

const useContentLogic = () => {
  const config: any = {
    bucketName: process.env.REACT_APP_AWS_BUCKET_NAME,
    region: process.env.REACT_APP_AWS_REGION,
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
  };
  const updateContent = (pageName: string, data: any, successCallback: any) => {
    updateByElementIdApi(
      { pageName, data },
      (data: any) => {
        successCallback(data);
        toast("Data updated successfully !", {
          autoClose: 3000,
        });
      },
      (err: any) => {}
    );
  };

  const uploadImageToS3Bucket = async (file: any, successCallback: any, errorCallback?: any) => {
    try {
      const formData=new FormData();
      formData.append("image", file);
      const result = await uploadFileApi(formData)
      successCallback(result);
    } catch (error) {
      toast.error("unable to upload image. Please try again later");
      console.error(error);
      errorCallback && errorCallback(error);
    }
  };

  const sendEmail = ( data: any, successCallback?: any, errorCallback?: any) => {
    sendEmailApi(
      data,
      (data: any) => {
        successCallback && successCallback(data);
        toast("Submitted !", {
          autoClose: 3000,
        });
      },
      (err: any) => {
        errorCallback && errorCallback(err);
      }
    );
  };

  return {
    updateContent,
    uploadImageToS3Bucket,
    sendEmail
  };
};

export { useContentLogic };
