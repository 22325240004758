import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Image,
  Modal,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import styles from "./ProductsAdmin.module.css";
import { getContentApi, updateProductsContentApi } from "../../apis";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faGear, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useContentLogic } from "../../hooks/useContentLogic";
import { Editor } from "@tinymce/tinymce-react";
import parse from "html-react-parser";

const ProductsAdmin = () => {
  const [content, setContent] = useState<any>({});
  const [title, setTitle] = useState<string>("");
  const [items, setItems] = useState<Array<any>>([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false);
  const [selectedElement, setSelectedElement] = useState<any>({});
  const [selectedImage, setSelectedImage] = useState<any>();
  const [productTitle, setProductTitle] = useState<string>("");
  const [productDescription, setProductDescription] = useState<string>("");
  const [selectedElementIndex, setSelectedElementIndex] = useState<any>();
  const [errors, setErrors] = useState<any>({
    titleError: "",
  });
  const [isLoadingSubmission, setIsLoadingSubmission] =
    useState<boolean>(false);
  const [isImageLoading, setIsImageLoading] = useState<boolean>(false);
  const { uploadImageToS3Bucket } = useContentLogic();
  const editorRef = useRef<any>(null);
  const onFetchContent = () => {
    getContentApi(
      (data) => {
        setContent(data.data);
      },
      () => {}
    );
  };

  useEffect(() => {
    onFetchContent();
  }, []);

  const validate = () => {
    let isValid = true;
    let _error = {
      titleError: "",
      descriptionError: "",
    };
    if (title?.trim()?.length === 0) {
      isValid = false;
      _error = { ..._error, titleError: "Field cannot be empty !" };
    }
    setErrors(_error);
    return isValid;
  };

  const onSubmitForm = () => {
    if (validate()) {
      setIsLoadingSubmission(true);
      const data = {
        id: content?.product?._id,
        title,
        items,
      };
      updateProductsContentApi(
        data,
        () => {
          onFetchContent();
          setIsLoadingSubmission(false);
        },
        () => {
          setIsLoadingSubmission(false);
        }
      );
    }
  };

  const onSubmitEditOrCreate = (data: any) => {
    if (validate()) {
      setIsLoadingSubmission(true);
      updateProductsContentApi(
        data,
        () => {
          onFetchContent();
          setIsLoadingSubmission(false);
        },
        () => {
          setIsLoadingSubmission(false);
        }
      );
    }
  };

  useEffect(() => {
    if (content?.product) {
      setTitle(content?.product?.title);
      setItems(content?.product?.items || []);
    }
  }, [content]);

  const renderEditModal = () => {
    return (
      <>
        <Modal
          onHide={() => {
            setIsEditModalOpen(!isEditModalOpen);
            setSelectedImage(undefined);
            setSelectedElementIndex(undefined);
            setSelectedElement({});
          }}
          show={isEditModalOpen}
        >
          <Modal.Body>
            <Modal.Header closeButton></Modal.Header>
            <Form.Group className="mt-1">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                value={selectedElement?.title}
                onChange={(event) => {
                  setSelectedElement({
                    ...selectedElement,
                    title: event.target.value,
                  });
                }}
                placeholder="Title"
                className={styles.formItem}
              />
              <p className={styles.errorMsg}>{errors.titleError}</p>
            </Form.Group>
            <Form.Group className="mt-1">
              <Form.Label>Description</Form.Label>
              <Editor
                tinymceScriptSrc={"/tinymce/tinymce.min.js"}
                onInit={(evt:any, editor:any) => (editorRef.current = editor)}
                onEditorChange={(env:any) => {
                  setSelectedElement({
                    ...selectedElement,
                    description: env,
                  });
                  setSelectedElement({
                    ...selectedElement,
                    description: env,
                  });
                }}
                value={selectedElement?.description}
                init={{
                  height: 200,
                  menubar: false,
                  plugins: [
                    "a11ychecker",
                    "advlist",
                    "advcode",
                    "advtable",
                    "autolink",
                    "checklist",
                    "export",
                    "lists",
                    "link",
                    "image",
                    "charmap",
                    "preview",
                    "anchor",
                    "searchreplace",
                    "visualblocks",
                    "powerpaste",
                    "fullscreen",
                    "formatpainter",
                    "insertdatetime",
                    "media",
                    "table",
                    "help",
                    "wordcount",
                  ],
                  font_size_formats: "8px 10px 12px 14px 16px 18px 24px 36px 48px" ,
                  toolbar:
                    "undo redo | casechange blocks fontsize | bold italic backcolor | " +
                    "alignleft aligncenter alignright alignjustify | " +
                    "bullist numlist checklist outdent indent | removeformat | a11ycheck code table help",
                  content_style:
                    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                }}
              />
              <p className={styles.errorMsg}>{errors.descriptionError}</p>
            </Form.Group>
            <Card>
              {isImageLoading ? (
                <Spinner
                  className="align-self-center m-5"
                  animation="border"
                  size="sm"
                />
              ) : (
                <Card.Img
                  variant="top"
                  className={styles.card_img}
                  src={
                    selectedImage ? selectedImage : selectedElement?.image_url
                  }
                  onClick={() => {
                    let imageUploader = document.getElementById(
                      "product_image_uploader"
                    );
                    if (imageUploader) {
                      imageUploader.click();
                    }
                  }}
                />
              )}

              <Row>
                <input
                  onChange={(event: any) => {
                    setIsImageLoading(true);
                    uploadImageToS3Bucket(
                      event.target.files[0],
                      (data: any) => {
                        setSelectedImage(data);
                        setIsImageLoading(false);
                      },
                      () => {
                        setIsImageLoading(false);
                      }
                    );
                  }}
                  type={"file"}
                  accept=".png"
                  style={{ display: "none" }}
                  id="product_image_uploader"
                />

                {!isImageLoading ? (
                  <>
                    <Col sm={"6"}>
                      <Button
                        onClick={() => {
                          let _items = items;
                          if (selectedImage) {
                            _items[selectedElementIndex].image_url =
                              selectedImage;
                          }
                          _items[selectedElementIndex].title =
                            selectedElement?.title;
                          _items[selectedElementIndex].description =
                            selectedElement?.description;
                          setItems(_items);
                          onSubmitEditOrCreate({
                            id: content?.product?._id,
                            title,
                            items: _items,
                          });
                          setSelectedImage(undefined);
                          setSelectedElementIndex(undefined);
                          setSelectedElement({});
                          setIsEditModalOpen(!isEditModalOpen);
                        }}
                        className="w-100"
                        variant="success"
                      >
                        Edit
                      </Button>
                    </Col>
                  </>
                ) : null}
              </Row>
            </Card>
          </Modal.Body>
        </Modal>
      </>
    );
  };

  const renderCreateModal = () => {
    return (
      <>
        <Modal
          onHide={() => {
            setIsCreateModalOpen(!isCreateModalOpen);
            setSelectedImage(undefined);
            setProductDescription("");
            setProductTitle("");
          }}
          show={isCreateModalOpen}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <Form.Group className="mt-1">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                value={productTitle}
                onChange={(event) => {
                  setProductTitle(event.target.value);
                }}
                placeholder="Title"
                className={styles.formItem}
              />
              <p className={styles.errorMsg}>{errors.titleError}</p>
            </Form.Group>
            <Form.Group className="mt-1">
              <Form.Label>Description</Form.Label>
              <Editor
                tinymceScriptSrc={"/tinymce/tinymce.min.js"}
                onInit={(evt, editor) => (editorRef.current = editor)}
                onEditorChange={(env) => {
                  setProductDescription(env);
                }}
                value={productDescription}
                init={{
                  height: 200,
                  menubar: false,
                  plugins: [
                    "a11ychecker",
                    "advlist",
                    "advcode",
                    "advtable",
                    "autolink",
                    "checklist",
                    "export",
                    "lists",
                    "link",
                    "image",
                    "charmap",
                    "preview",
                    "anchor",
                    "searchreplace",
                    "visualblocks",
                    "powerpaste",
                    "fullscreen",
                    "formatpainter",
                    "insertdatetime",
                    "media",
                    "table",
                    "help",
                    "wordcount",
                  ],
                  font_size_formats: "8px 10px 12px 14px 16px 18px 24px 36px 48px",
                  toolbar:
                    "undo redo | casechange blocks fontsize | bold italic backcolor | " +
                    "alignleft aligncenter alignright alignjustify | " +
                    "bullist numlist checklist outdent indent | removeformat | a11ycheck code table help",
                  content_style:
                    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                }}
              />
              <p className={styles.errorMsg}>{errors.titleError}</p>
            </Form.Group>
            <Card>
              {isImageLoading ? (
                <Spinner
                  className="align-self-center m-5"
                  animation="border"
                  size="sm"
                />
              ) : selectedImage ? (
                <Card.Img
                  variant="top"
                  src={selectedImage}
                  className={styles.card_img}
                  onClick={() => {
                    let imageUploader = document.getElementById(
                      "product_image_uploader"
                    );
                    if (imageUploader) {
                      imageUploader.click();
                    }
                  }}
                />
              ) : (
                <input
                  onChange={(event: any) => {
                    setIsImageLoading(true);
                    uploadImageToS3Bucket(
                      event.target.files[0],
                      (data: any) => {
                        setSelectedImage(data);
                        setIsImageLoading(false);
                      },
                      () => {
                        setIsImageLoading(false);
                      }
                    );
                  }}
                  type={"file"}
                  accept=".png"
                  // style={{ display: "none" }}
                  id="product_image_uploader"
                />
              )}
            </Card>

            <Row>
              {selectedImage && !isImageLoading ? (
                <>
                  <Col sm={"6"}>
                    <Button
                      onClick={() => {
                        setItems([
                          ...items,
                          {
                            image_url: selectedImage,
                            title: productTitle,
                            description: productDescription,
                          },
                        ]);
                        onSubmitEditOrCreate({
                          id: content?.product?._id,
                          title,
                          items: [
                            ...items,
                            {
                              image_url: selectedImage,
                              title: productTitle,
                              description: productDescription,
                            },
                          ],
                        });
                        setSelectedImage(undefined);
                        setProductDescription("");
                        setProductTitle("");
                        setIsCreateModalOpen(!isCreateModalOpen);
                      }}
                      className="w-100"
                      variant="success"
                    >
                      Add
                    </Button>
                  </Col>
                </>
              ) : null}
            </Row>
          </Modal.Body>
        </Modal>
      </>
    );
  };

  return (
    <Container className="mt-5">
      <Form className="w-lg-75">
        <Form.Group>
          <Form.Label>Title</Form.Label>
          <Form.Control
            type="text"
            value={title}
            onChange={(event) => {
              setTitle(event.target.value);
              setErrors({ ...errors, titleError: "" });
            }}
            placeholder="Title"
            className={styles.formItem}
          />
          <p className={styles.errorMsg}>{errors.titleError}</p>
        </Form.Group>
      </Form>
      <Row className="justify-content-end">
        <Col sm={2}>
          <Button
            onClick={() => {
              setIsCreateModalOpen(true);
            }}
            variant="success"
            className="mb-2 w-100"
          >
            Add New
          </Button>
        </Col>
      </Row>
      <Table bordered responsive hover size="sm">
        <thead>
          <tr>
            <th>#</th>
            <th>Image</th>
            <th>Title</th>
            <th>Description</th>
            <th>{<FontAwesomeIcon className="ps-2 pe-2" icon={faGear} />}</th>
          </tr>
        </thead>
        <tbody>
          {items?.map((item: any, index: number) => {
            return (
              <tr>
                <td>{index + 1}</td>
                <td>
                  {
                    <Image
                      className={styles.image_preview}
                      src={item.image_url}
                    />
                  }
                </td>
                <td>{item?.title}</td>
                <td>{parse(item?.description || "")}</td>
                <td>
                  <FontAwesomeIcon
                    onClick={() => {
                      setIsEditModalOpen(true);
                      setSelectedElement(item);
                      setSelectedElementIndex(index);
                    }}
                    className="ps-2 pe-2"
                    role={"button"}
                    icon={faEdit}
                  />
                  <FontAwesomeIcon
                    onClick={() => {
                      let _items = [...items];
                      _items.splice(index, 1);
                      setItems(_items);
                      onSubmitEditOrCreate({
                        id: content?.product?._id,
                        title,
                        items: _items,
                      });
                    }}
                    className="ps-2 pe-2"
                    role={"button"}
                    icon={faTrash}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <Button onClick={onSubmitForm} className="ps-5 pe-5 mt-5">
        {isLoadingSubmission ? (
          <Spinner size={"sm"} animation="border" variant="primary" />
        ) : (
          "Update"
        )}
      </Button>
      {renderEditModal()}
      {renderCreateModal()}
    </Container>
  );
};

export { ProductsAdmin };
