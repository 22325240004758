import { toast } from "react-toastify";
import { axiosInstance } from "../axios";

const getContentApi = async (
  successCallback: (response: any) => void,
  errorCallback: (error: any) => void
) => {
  try {
    let response = await axiosInstance.get("/get-content");
    successCallback(response);
  } catch (error) {
    errorCallback(error);
  }
};

const updateByElementIdApi = async (
  body: any,
  successCallback: (response: any) => void,
  errorCallback: (error: any) => void
) => {
  try {
    let response = await axiosInstance.post("/update-element", body);
    successCallback(response);
  } catch (error) {
    errorCallback(error);
  }
};

const sendEmailApi = async (
  body: any,
  successCallback: (response: any) => void,
  errorCallback: (error: any) => void
) => {
  try {
    let response = await axiosInstance.post("/send-mail", body);
    successCallback(response);
  } catch (error) {
    errorCallback(error);
  }
};

const updateBannerContentApi = async (
  body: any,
  successCallback: (response: any) => void,
  errorCallback: (error: any) => void
) => {
  try {
    let response = await axiosInstance.post("/banner/update", body);
    successCallback(response);
    toast.success("Updated Successfully !", { autoClose: 3000 });
  } catch (error) {
    errorCallback(error);
    toast.error("something went wrong! ", { autoClose: 3000 });
  }
};

const updateProductsContentApi = async (
  body: any,
  successCallback: (response: any) => void,
  errorCallback: (error: any) => void
) => {
  try {
    let response = await axiosInstance.post("/product/update", body);
    successCallback(response);
    toast.success("Updated Successfully !", { autoClose: 3000 });
  } catch (error) {
    errorCallback(error);
    toast.error("something went wrong! ", { autoClose: 3000 });
  }
};

const updateSponsorContentApi = async (
  body: any,
  successCallback: (response: any) => void,
  errorCallback: (error: any) => void
) => {
  try {
    let response = await axiosInstance.post("/sponsor/update", body);
    successCallback(response);
    toast.success("Updated Successfully !", { autoClose: 3000 });
  } catch (error) {
    errorCallback(error);
    toast.error("something went wrong! ", { autoClose: 3000 });
  }
};

const updateWhyContentApi = async (
  body: any,
  successCallback: (response: any) => void,
  errorCallback: (error: any) => void
) => {
  try {
    let response = await axiosInstance.post("/why/update", body);
    successCallback(response);
    toast.success("Updated Successfully !", { autoClose: 3000 });
  } catch (error) {
    errorCallback(error);
    toast.error("something went wrong! ", { autoClose: 3000 });
  }
};

const updateFaqContentApi = async (
  body: any,
  successCallback: (response: any) => void,
  errorCallback: (error: any) => void
) => {
  try {
    let response = await axiosInstance.post("/faq/update", body);
    successCallback(response);
    toast.success("Updated Successfully !", { autoClose: 3000 });
  } catch (error) {
    errorCallback(error);
    toast.error("something went wrong! ", { autoClose: 3000 });
  }
};

const uploadFileApi = async (
  body:any
) =>{
  try {
    const response = await axiosInstance.post('/uploader/do', body);
    return response.data
  } catch (error) {
    toast.error("something went wrong! ", {autoClose: 3000});
  }
}


export {
  getContentApi,
  updateByElementIdApi,
  sendEmailApi,
  updateBannerContentApi,
  updateProductsContentApi,
  updateWhyContentApi,
  updateFaqContentApi,
  updateSponsorContentApi,
  uploadFileApi
};
