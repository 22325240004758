import React, { useCallback, useEffect, useState } from "react";
import {
  Row,
  Container,
  Col,
  Image,
  Form,
  Accordion,
  Modal,
  Card,
  Spinner,
} from "react-bootstrap";
import { getContentApi } from "../../apis";
import { BANNER, CIRCLE_DOT, WHY_VOTEMAX } from "../../assets/image";
import {
  PrimaryButton,
} from "../../components";
import { useContentLogic } from "../../hooks/useContentLogic";
import styles from "./Home.module.css";
import { Buffer } from "buffer";
import Slider from "react-slick";
import parse from "html-react-parser";
import "./style.css";

Buffer.from("anything", "base64");
window.Buffer = window.Buffer || require("buffer").Buffer;

const Home = () => {
  const [content, setContent] = useState<any>({});
  const [selectedProduct, setSelectedProduct] = useState<any>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState<any>({
    nameError: "",
    emailError: "",
    phoneError: "",
  });
  const { sendEmail } = useContentLogic();
  const [isLoadingSubmission, setIsLoadingSubmission] = useState(false);

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 475,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const getContent = useCallback(() => {
    getContentApi(
      (response: any) => {
        const { status, data } = response;
        if (status === 200) {
          setContent(data);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }, []);

  useEffect(() => {
    getContent();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openInNewTab = (url: string) => {
    window.open(url, "_blank", "noreferrer");
  };

  const onClickButton = (section_id: string) => {
    let element: any = document.getElementById(section_id);
    if (element) {
      element.scrollIntoView({ behaviour: "smooth" });
    }
  };

  const renderHomeBanner = () => {
    return (
      <Row id="home-banner-section" className={styles.homePageBannerRow}>
        <div className={styles.homePageBanner}>
          <Container className="h-100">
            <Row className="h-100">
              <Col
                xs="12"
                lg="6"
                className="h-100 d-flex flex-column justify-content-center"
              >
                <h1 className="color-blue font-64 fw-bold">
                  {content?.banner?.title}
                </h1>

                <h2 className="mt-2">{content?.banner?.subTitle}</h2>
                <PrimaryButton
                  title="Contact Us"
                  onClick={() => {
                    onClickButton("contact-us-section");
                  }}
                  btnClass={`${styles.contactUsBtn} mt-5`}
                />
              </Col>
              <Col
                xs="12"
                lg="6"
                className={"align-items-center " + styles.bannerImageCol}
              >
                <Image src={BANNER} style={{ width: "100%" }} />
              </Col>
            </Row>
          </Container>
        </div>
      </Row>
    );
  };

  const validate = () => {
    let isValid = true;
    let _error = {
      nameError: "",
      emailError: "",
      phoneError: "",
    };
    if (name.length < 3) {
      isValid = false;
      _error = { ..._error, nameError: "Please enter a valid name!" };
    }
    if (phone.length < 10) {
      isValid = false;
      _error = { ..._error, phoneError: "Please enter a valid phone number!" };
    }
    if (!email.includes("@") && !email.includes(".")) {
      isValid = false;
      _error = { ..._error, emailError: "Please enter a valid Email!" };
    }
    setErrors(_error);
    return isValid;
  };

  const onSendEmail = () => {
    if (validate()) {
      setIsLoadingSubmission(true);
      sendEmail(
        {
          name: name,
          phone: phone,
          email: email,
        },
        () => {
          setName("");
          setPhone("");
          setEmail("");
          setIsLoadingSubmission(false);
        },
        () => {
          setIsLoadingSubmission(false);
        }
      );
    }
  };

  const renderProducts = () => {
    return (
      <Row id="products-section">
        <div className={styles.homeProducts}>
          <Container className="pb-5">
            <Row className="pt-5">
              <Col className="pt-5 pb-2">
                <p className="font-48 fw-bold text-center">
                  {content?.product?.title}
                </p>
              </Col>
            </Row>
            <Row className="pb-5">
              {content?.product?.items?.map((item: any, index: number) => {
                return (
                  <Col key={index} xs="12" sm="12" md="12" lg="6" className="mt-5">
                    <div
                      onClick={() => {
                        setSelectedProduct(item);
                        setIsModalOpen(true);
                      }}
                      className={styles.productsItemContainer}
                    >
                      <Image
                        id="product_image_1"
                        src={item?.image_url}
                        className="w-75"
                      />
                    </div>
                    <p className="text-center font-20 mt-3">{item?.title}</p>
                  </Col>
                );
              })}
            </Row>
          </Container>
        </div>
      </Row>
    );
  };

  const renderWhyVotemax = () => {
    return (
      <Row className={styles.whyVotemaxRow}>
        <div id="why-votemax-section" className={`${styles.whyVotemax} mb-5`}>
          <Container>
            <Row className={styles.whyVotemaxListRow}>
              <Col
                xs="12"
                sm="12"
                md="12"
                lg="6"
                className={styles.whyVotomaxImgCol}
              >
                <Image src={WHY_VOTEMAX} />
              </Col>
              <Col
                xs="12"
                sm="12"
                md="12"
                lg="6"
                className={styles.whyVotemaxListCol}
              >
                <h2 className="fw-bold font-48 mb-5">{content?.why?.title}</h2>
                <ul>
                  {content?.why?.items?.map((item: any, index: number) => {
                    return (
                      <li key={index} className={index===content?.why?.items?.length-1?'':styles.borderLeftDotted}>
                        <Image src={CIRCLE_DOT} />
                        <p className="ms-4">{parse(item?.title || "")}</p>
                      </li>
                    );
                  })}
                </ul>
                <PrimaryButton
                  title="Contact Us"
                  onClick={() => {
                    onClickButton("contact-us-section");
                  }}
                  btnClass={`${styles.contactUsBtn} mt-5`}
                />
              </Col>
            </Row>
          </Container>
        </div>
        <div id="contact-us-section" className={`${styles.ourSolutionsDiv}`}>
          <Container className={styles.ourSolutions}>
            <Row>
              <Col>
                <h2 className="font-48 fw-bold text-center text-white">
                  {content?.why?.solutionsHeading}
                </h2>
                <p className="font-20 fw-lighter text-center text-white opacity-75">
                  {parse(content?.why?.solutionsDescription || "")}
                </p>
              </Col>
            </Row>
            <Row className="ps-5 pe-5 mt-5">
              <Col xs="12" sm="12" md="12" lg="3" className="mt-2">
                <Form.Control
                  type="text"
                  className={`${styles.ourSolutionInputs}`}
                  placeholder="Your name"
                  value={name}
                  onChange={(event: any) => {
                    setName(event.target.value);
                    setErrors({ ...errors, nameError: "" });
                  }}
                />
                <p className="error-msg">{errors.nameError}</p>
              </Col>
              <Col xs="12" sm="12" md="12" lg="3" className="mt-2">
                <Form.Control
                  type="text"
                  className={`${styles.ourSolutionInputs}`}
                  placeholder="Your phone"
                  value={phone}
                  onChange={(event: any) => {
                    setPhone(event.target.value);
                    setErrors({ ...errors, phoneError: "" });
                  }}
                />
                <p className="error-msg">{errors.phoneError}</p>
              </Col>
              <Col xs="12" sm="12" md="12" lg="3" className="mt-2">
                <Form.Control
                  type="email"
                  className={`${styles.ourSolutionInputs}`}
                  placeholder="Your email"
                  value={email}
                  onChange={(event: any) => {
                    setEmail(event.target.value);
                    setErrors({ ...errors, emailError: "" });
                  }}
                />
                <p className="error-msg">{errors.emailError}</p>
              </Col>
              <Col xs="12" sm="12" md="12" lg="3" className="mt-2">
                <PrimaryButton
                  onClick={onSendEmail}
                  title={
                    isLoadingSubmission ? (
                      <Spinner
                        size={"sm"}
                        animation="border"
                        variant="primary"
                      />
                    ) : (
                      "Explore our solutions"
                    )
                  }
                  btnClass={
                    styles.ourSolutionsFormBtn + " bg-white color-blue w-100"
                  }
                />
              </Col>
            </Row>
          </Container>
        </div>
      </Row>
    );
  };

  const renderFAQ = () => {
    return (
      <Row id="faq-section" className={styles.faqRow}>
        <div className={styles.faqDiv}>
          <Container className={styles.faqContainer}>
            <Row>
              <Col xs="12" sm="12" md="12" lg="6">
                <h2 className="font-48 fw-bold">{content?.faq?.title}</h2>
              </Col>
              <Col xs="12" sm="12" md="12" lg="6">
                <Accordion
                  defaultActiveKey={`${content?.faq?.items?.length - 1}`}
                >
                  {content?.faq?.items?.map((item: any, index: number) => {
                    return (
                      <Accordion.Item
                        key={index}
                        eventKey={`${index}`}
                        bsPrefix={styles.faqItems}
                      >
                        <Accordion.Header bsPrefix="faqHeader">
                          <span>{item?.title}</span>
                        </Accordion.Header>
                        <Accordion.Body>
                          <p>{parse(item?.description || "")}</p>
                        </Accordion.Body>
                      </Accordion.Item>
                    );
                  })}
                </Accordion>
              </Col>
            </Row>
          </Container>
        </div>
      </Row>
    );
  };

  const renderSponsers = () => {
    return (
      <Row className={styles.sponsorsRow}>
        <div className={styles.sponsorsDiv}>
          <Container className={styles.sponsorsContainer} id="sponsorsSlider">
            <Row>
              <h2 className="font-48 fw-bold text-center">
                {content?.sponsor?.title}
              </h2>
            </Row>
            <Slider {...settings}>
              {content?.sponsor?.items?.map((item: any, index: number) => {
                return (
                  <div key={index} className={styles.sponsorsItems}>
                    <Image
                      id="sponsor_img_1"
                      className="image-responsive w-100"
                      src={item?.image_url || ""}
                      onClick={() => {
                        openInNewTab(item?.link);
                      }}
                    />
                  </div>
                );
              })}
            </Slider>
          </Container>
        </div>
      </Row>
    );
  };

  const renderProductModal = () => {
    return (
      <Modal
        onHide={() => {
          setIsModalOpen(!isModalOpen);
        }}
        centered
        show={isModalOpen}
      >
        <Modal.Body>
          <Modal.Header closeButton></Modal.Header>
          <Card>
            <Card.Img
              variant="top"
              src={selectedProduct?.image_url}
              onClick={() => {
                let imageUploader = document.getElementById(
                  "product_image_uploader"
                );
                if (imageUploader) {
                  imageUploader.click();
                }
              }}
            />
            <Card.Body>
              <Card.Text>
                {parse(selectedProduct?.description || "")}
              </Card.Text>
            </Card.Body>
          </Card>
        </Modal.Body>
      </Modal>
    );
  };

  return (
    <>
      {renderHomeBanner()}
      {renderProducts()}
      {renderWhyVotemax()}
      {renderFAQ()}
      {renderSponsers()}
      {renderProductModal()}
    </>
  );
};

export { Home };
