export const PRODUCT_IMAGE_1 = require("./product-image-1.png");
export const PRODUCT_IMAGE_2 = require("./product-image-2.png");
export const PRODUCT_IMAGE_3 = require("./product-image-3.png");
export const PRODUCT_IMAGE_4 = require("./product-image-4.png");
export const WHY_VOTEMAX = require("./why-votemax.png");
export const CIRCLE_DOT = require("./circle-dot.png");
export const FB_ICON = require("./fb.png")
export const TWITTER_ICON = require("./twitter.png")
export const CAM_ICON = require("./cam.png")
export const BANNER = require("./banner.png")
export const LOGO_SMALL = require("./Logo-votemax-small.png")
export const LOGO = require("./Logo-votemax.png")