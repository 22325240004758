import React, { useEffect } from "react";
import {
  Container,
} from "react-bootstrap";
import { Outlet } from "react-router-dom";
import { Footer, Header } from "../../components";

const FullPageLayout = (props: any) => {

  useEffect(()=>{
    window.addEventListener("scroll", (e) => {
      if (window.scrollY > 10) {
        document
          .getElementById("site-header")
          ?.classList?.add("bg-transparent");
      } else {
        document
          .getElementById("site-header")
          ?.classList?.remove("bg-transparent");
      }
    });
    return () => {
      window.removeEventListener("scroll", () => {});
    };
  },[])

  return (
    <Container fluid style={{ position: "relative" }}>
      <Header/>
      <Outlet />
      <Footer/>
    </Container>
  );
};

export { FullPageLayout };
